<style lang="scss" scoped>
	@import "./seller-participation.partial";
    .return {
        background: $primary-color;
        color: white;
        right: 3rem;
        top: 0rem;
        z-index: 1;
        border-radius: 35px;
        height: 60px;
        width: 60px;
        border: none;
    }
    .overflow-container{
        max-height: 60vh;
        overflow-x: auto;
        padding: 20px !important;
    }
    .page-table-line-special{
        background: #F7F7F7;
    }
    .request-name{
        cursor: pointer;
        &:hover{
            text-decoration: underline;
        }
    }
    .seller-buttons-container{
        margin-left: 20px;
        margin-bottom: 2vh;
        display: flex;
        align-items: flex-end;
        gap: 3vw;
        span{
            color: var(--primary-color);
            border: 1px solid var(--primary-color);
            border-radius: 10px;
            padding: 1vh 1vw;
        }
    }
    .name-table-head{
        display: flex;
        align-items: center;
        cursor: pointer;
    }
</style>
<template>
    <div class="container-fluid" style="min-height:60vw">
        <div class="seller-buttons-container">
            <StandardInput :placeholder="'Selecione o cliente'" title="cliente" width="20%"
                     type="text" :action="value => clientFilter = value"/>
            <span v-if="clientFilter">{{ clientFilter }}</span>        
            <StandardButton text="Limpar filtros" :action="cleanFilters"/>
        </div>
        
        <div class="row overflow-container" id="modalBodyPrint" v-show="!loading">
            <div class="col" v-if="!current_request">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="pl-3 text-center">ID</th>
                            <th class="name-table-head" @click="toogleOrder">
                                CLIENTE
                                <span v-if="nameOrder == 'desc'" class="material-icons-outlined">expand_less</span>
                                <span v-else-if="nameOrder == 'asc'" class="material-icons-outlined">expand_more</span>
                                <span v-else class="material-icons-outlined">unfold_more</span>
                            </th>
                            <th class="text-left">PRODUTOS</th>
                            <th style="width:25px">CONCORRENTES</th>
                            <th class="text-center">STATUS</th>
                            <th>Criada em</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(request, index) in showRequest" :class="getSpecialBackground(index)" v-bind:key="request.id">
                            <td class="text-center"><strong>#{{ request.id || '-'}}</strong></td>
                            <td @click="clientFilter = request.client.group" class="text-uppercase request-name">{{ request.client.group || '-'}}</td>
                            <td class="text-left">
                                <span class="prod-count" @click="current_request = request">
                                    {{ request.products_count }}
                                </span>
                            </td>
                            <td class="text-left">{{ request.sellers_count }}</td>
                            <td class="text-center">
                                <cc-status :status="request.request_seller_status"></cc-status>
                            </td>
                            <td>{{ request.created_at | formatDate }}</td>
                            <td>
                                <span @click="remove(request)" style="color: lightcoral; cursor: pointer;" class="material-icons-outlined">delete</span>
                            </td>
                        </tr>
                    </tbody>
                    
                </table>
                <Pagination
                        classes="orange justify-end"
                        :items_by_page_value="15"
                        :total="total_items"
                        :page="1"
                        v-on:change="load_requests($event)"
                    ></Pagination>
            </div>
            <div class="col" v-if="current_request">
                <SellerOffers
                    :isSellersManagement="isSellersManagement"
                    :request_id="current_request.id"
                    :seller_id="request_seller.seller.id" />
            </div>
        </div>
        <cc-loader-full v-if="loading"/>
    </div>
</template>
<script>
import RequestService from "@/services/v3/request.service";
import SellerService from "@/services/v3/providers/seller.service";
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import ErrorHandlerService from "@/services/ErrorHandlerService";
import SellerOffers from "@/modals/providers/sellers/_offers.partial"
import StandardInput from '@/components/ui/inputs/StandardInput.vue'
import StandardButton from '@/components/ui/buttons/StandardButton.vue';
import Pagination from "@/components/cliente/base-components/Pagination";

export default {
    mixins: [ loaderMixin ],
    props: [ "request_seller", 'selectedStatus', 'request_id'],
    components: { SellerOffers, StandardInput, StandardButton, Pagination },
    data() {
        return {
            loading: false,
            isSellersManagement: true,
            service: new RequestService(),
            seller_svc: new SellerService(),
            requests: [],
            current_seller: null,
            current_request: null,
            clientFilter: '',
            nameOrder: undefined,
            total_items: undefined
        }
    },
    watch: {
        clientFilter(){
            this.load_requests(1)
        },
        selectedStatus(){
            this.load_requests(1)
        },
        nameOrder(){
            this.load_requests(1)
        }
    },
    computed: {
        showRequest() {
            let resp = this.requests
            // if (this.clientFilter) {
            //     resp =  resp.filter(c => c.name.toUpperCase().includes(this.clientFilter.toUpperCase()))
            // }
            // if (this.selectedStatus) {
            //     resp =  resp.filter(c => {
            //         if (c.request_seller_status == 'NOT_TYPED' && this.selectedStatus == 7) return true
            //         else if(c.request_seller_status == 'TYPED' && this.selectedStatus == 12) return true
            //         else if(c.request_seller_status == 'FROZEN' && this.selectedStatus == 10) return true
            //         else if(c.request_seller_status == 'ORDERED' && this.selectedStatus == 5) return true
            //         else if(c.request_seller_status == 'NOT_OFFERED' && this.selectedStatus == 1) return true
            //     })
            // }
            // if (this.nameOrder == 'asc' || this.nameOrder == 'desc') resp.sort((a,b) => {
            //     if (a.name < b.name) {
            //         return -1;
            //     }
            //     if (a.name > b.name) {
            //         return 1;
            //     }
            //     return 0;
            // })
            // if (this.nameOrder == 'desc') resp.reverse()
            return resp
        }
    },
    methods: {
        load_requests(page = 1) {
            this.loading = true;
            let params = { page, order: this.nameOrder, seller_id: this.request_seller.seller.id, status: this.selectedStatus, client_name: this.clientFilter, with_pagination: 1 }
            return this.service.list(params).then((response) => {
                this.loading = false;
                this.requests = response.data.data
                this.total_items = response.data.meta.total
            }).catch(err => {
                ErrorHandlerService.handle(error, this.$store);
            })
        },
        remove(request_seller) {
            this.confirm_action({
            message: "Confirma remoção do vendedor?",
            subtitle: "O vendedor será removido da cotação e do cliente!",
            callback: () => {
                this.present_loader("Removendo vendedor da cotação...")
                return this.service.remove_seller(request_seller.id, this.request_seller.seller.id )
                            .then(() => this.dismiss_loader())
                            .then(() => {
                                this.present_loader("Removendo vendedor do cliente...")
                                this.seller_svc.deactivate_client(this.request_seller.seller.id, request_seller.client.id)
                                .then(this.load_requests)
                                .then(() => this.dismiss_loader())
                            })
            }
            })
        },
        cleanFilters(){
            this.nameOrder = undefined
            this.clientFilter = undefined
        },
        toogleOrder(){
            if (!this.nameOrder) this.nameOrder = 'asc'
            else if (this.nameOrder == 'desc') this.nameOrder = 'asc'
            else this.nameOrder = 'desc'
        },
        getSpecialBackground(index) {
            if (index % 2 != 0) return 'page-table-line-special'
        }
    },
    created() {
        this.load_requests()
    }
};
</script>
