<template>
    <!-- <div id="seller" class="col">
        <div class="list-group">
            <ValidationObserver v-slot="{ handleSubmit }">
                <form action="" v-if="seller" @submit.prevent="handleSubmit( save )">
                    <div class="row d-flex justify-content-center">
                        <div class="col">
                            <label>Nome:<span style="color:red !important">*</span></label>
                            <ValidationProvider rules="required" v-slot="v">
                                <cc-input type="text" v-model="seller.name"></cc-input>
                                <span class="error">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col">
                            <label>Email:<span style="color:red !important">*</span></label>
                            <ValidationProvider rules="required" v-slot="v">
                                <cc-input type="text" v-model="seller.email"></cc-input>
                                <span class="error">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>

                    <div class="row d-flex justify-content-center">
                        <div class="col">
                            <label>Perfil:<span style="color:red !important">*</span></label>
                            <ValidationProvider rules="required" v-slot="v" name="prof">
                                <cc-select-v2 :tracker="'id'" :text="'name'" v-model="seller.profile" :options="profiles"></cc-select-v2>
                                <span class="error">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col">
                            <label>Telefone:<span style="color:red !important">*</span></label>
                            <ValidationProvider rules="required" v-slot="v">
                                <cc-input type="text" v-mask="'(##) #.####-####'" v-model="seller.phone"></cc-input>
                                <span class="error">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>

                    <div class="row d-flex justify-content-center">
                        <div class="col">
                            <label>Login:<span style="color:red !important">*</span></label>
                            <ValidationProvider rules="required" v-slot="v">
                                <cc-input type="text" v-model="seller.login"></cc-input>
                                <span class="error">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col">
                            <label>Senha:<span style="color:red !important">*</span></label>
                            <ValidationProvider rules="required" v-slot="v">
                                <cc-input type="password" v-model="seller.password"></cc-input>
                                <span class="error">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <button ref="send-seller" type="submit" style="display:none;"></button>
                </form>
            </ValidationObserver>
        </div>
    </div> -->
    <div class="add-selller-body">
        <div class="add-seller-title-container">
            {{seller.provider.name }}
            <StandardButtonVue class="add-seller-button" :text="'Voltar'" :action="close" :iconName="'arrow_back'"/>
        </div>
        <div class="add-seller-body">
            <div class="add-seller-row">
                <StandardInputV2Vue class="add-seller-input"  :auto="true" :value="modalSeller.name" :action="setName" title="Nome" type="text"/>
                <StandardInputV2Vue class="add-seller-input" :auto="true" :value="modalSeller.email" :action="setEmail" title="Email" type="text"/>
            </div>
            <div class="add-seller-row">
                <StandardInputV2Vue class="add-seller-input" :auto="true" title="Perfil" type="select" :placeholder="getProfileName(seller.profile) " :list="formatList(profiles)" :action="setProfile"/>
                <StandardInputV2Vue class="add-seller-input" :auto="true" :value="modalSeller.phone" :action="setPhone" mask="(##) #.####-####" title="Telefone" type="text"/>
            </div>
            <div class="add-seller-row">
                <StandardInputV2Vue class="add-seller-input" :auto="true" :value="modalSeller.login" :action="setLogin" title="Login" type="text"/>
                <StandardInputV2Vue class="add-seller-input" :auto="true" :value="modalSeller.password" :action="setPassword" title="Senha" type="text"/>
            </div>
            <div class="add-selller-buttons">
                <StandardButtonVue class="add-seller-button" :text="'Cancelar'" :action="close" />
                <StandardButtonVue class="add-seller-button" :text="'Salvar'" :action="save" />
            </div>
        </div>
    </div>
</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import SellerService from '@/services/v3/providers/seller.service'
import StandardButtonVue from '../../../components/ui/buttons/StandardButton.vue';
import StandardInputV2Vue from '../../../components/ui/inputs/StandardInputV2.vue';

export default {
    mixins: [ loaderMixin ],
    components: {
        ValidationProvider,
		ValidationObserver,
        StandardButtonVue,
        StandardInputV2Vue
    },
    props: [ "seller", "profiles", "close" ],
    data() {
        return {
            svc: new SellerService(),
            modalSeller: {}
        }
    },
    created(){
        this.modalSeller = this.seller
    },
    methods: {
        getProfileName(profile){ return profile ? profile.name : '' },
        formatList(list) {
            const resp = []
            list.forEach(element => {
                resp.push({
                    text: element.name,
                    value: element
                })
            });
            return resp
        },
        setName(value){this.modalSeller.name = value},
        setEmail(value){this.modalSeller.email = value},
        setPhone(value){this.modalSeller.phone = value},
        setLogin(value){this.modalSeller.login = value},
        setPassword(value){this.modalSeller.password = value},
        setProfile(value) {this.modalSeller.profile = value},
        validateForm () {
            return new Promise((resolve, reject) => {
                const resp = []
                if (!this.modalSeller.name) resp.push('Por favor adicione um nome')
                if (!this.modalSeller.email) resp.push('Por favor adicione um email')
                if (!this.modalSeller.phone) resp.push('Por favor adicione um numero de telefone')
                if (!this.modalSeller.login) resp.push('Por favor adicione um login')
                if (!this.modalSeller.password) resp.push('Por favor adicione uma senha')
                if (!this.modalSeller.profile) resp.push('Por favor selecione um perfil')
                if (resp.length > 0) reject(resp)
                else resolve()
            })
        },
        save() {
            this.validateForm().then(() => {
                this.present_loader("Salvando vendedor...")
                return this.svc.save(this.modalSeller).then(() => {
                    this.present_info('Dados salvos com sucesso!')
                    this.$emit('reload')
                }).catch((err) => {
                    if(err.response && err.response.data.errors && err.response.data.errors.usu_login) {
                        this.$store.dispatch('notification/add', {
                            type: 'error',
                            message:  err.response.data.errors.usu_login[0]
                        })
                    }
                    if(err.response && err.response.data.errors && err.response.data.errors.usu_email) {
                        this.$store.dispatch('notification/add', {
                            type: 'error',
                            message:  err.response.data.errors.usu_email[0]
                        })
                    }
                    this.dismiss_loader()
                })
            }).catch(errors => {
                errors.forEach(error => {
                    const notification = {
                        type: 'error',
                        message: error
                    }
                    this.$store.dispatch('notification/add', notification)
                })
            })
            
        }
    }
}
</script>

<style>
.add-selller-body{padding: 1vh 5vw;}
.add-seller-title-container{
    display: flex;
    justify-content: space-between;
    font-size: 1.5em;
    color: var(--primary-color);
    margin-bottom: 3vh;
    align-items: center;
}
.add-seller-body{
    margin-top: 3vh;
    margin-left: 5vw;
}
.add-seller-row{
    display: flex;
    justify-content: space-between;
    margin-bottom: 4vh;
}
.add-selller-buttons{
    display: flex;
    justify-content: flex-end;
    gap: 5vw;
    margin-bottom: 5vh;
}
.add-seller-button{width: 15%;}
.add-seller-input{width: 45%}
@media only screen and (max-width: 750px) {
    .add-seller-row{flex-direction: column; gap: 5vh;}
    .add-seller-input{width: 100%}
    .add-seller-button{width: 45%;}
}
</style>
