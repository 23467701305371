<template>
    <div class="modal-mask" v-show="sellerInfo">
        <div class="modal-container">
            <div class="seller-header">
                <div style="display: flex; flex-direction: column;">
                    <span>Nome: {{ sellerInfo.name }}</span>
                    <span>Senha: {{ sellerInfo.password }}</span>
                </div>
                <div>
                    <span class="material-icons seller-header-close" @click="close">cancel</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        sellerInfo: {
            type: Object,
        }
    },
    data() {
        return {}
    },
    methods: {
        close() {
            this.$emit('close')
        },
    },
    mounted() {
        console.log(this.sellerInfo)
    },
}
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
    font-size: 1vw;
}

.modal-container {
    width: 30vw;
    margin: 0px auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    margin-top: 50vh;
}

.seller-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--primary-color);
    border-radius: 10px;
    padding: 1vh 1vw;
    font-size: 1.5em;
    color: white;
}

.seller-header-close {
    cursor: pointer;
    font-size: 1.5em;
}
</style>