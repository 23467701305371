import http from "./http";
import Service from "../Service";

/**
 * @type ProviderService
 */
export default class RequestService extends Service {
    update_offer(id, seller_id, product_id, data) {
        return http.post(
            `/admin/requests/${id}/sellers/${seller_id}/offers/${product_id}`,
            data,
            {
                headers: this.getHeaders()
            }
        );
    }

    request_offers(id, seller_id) {
        return http.get(
            `/clients/requests/${id}/sellers/${seller_id}/offers`,
            {
                headers: this.getHeaders()
            }
        );
    }

    request_offers_by_product(id, product_id) {
        return http.get(
            `/clients/requests/${id}/products/${product_id}/offers`,
            {
                headers: this.getHeaders()
            }
        );
    }

    general_request_report(id) {
        return http.get(
            `/clients/reports/requests/${id}/products`,
            {
                headers: this.getHeaders()
            }
        );
    }

    full_general_request_report(id) {
        return http.get(
            `/clients/reports/requests/${id}/products/all`,
            {
                headers: this.getHeaders()
            }
        );
    }

    export_store_report(id, store_id) {
        return http.get(
            `/clients/reports/excel/requests/${id}/store/${store_id}`,
            {
                headers: this.getHeaders(),
                responseType: "arraybuffer"
            }
        );
    }

    list(params) {
        return http.get(`/admin/requests`, {
            headers: this.getHeaders(),
            params
        });
    }

    sellers(id) {
        return http.get(`/admin/requests/${id}/sellers`, {
            headers: this.getHeaders()
        });
    }

    remove_seller(id, sellerid) {
        return http.delete(
            `/admin/requests/${id}/sellers/${sellerid}`,
            {
                headers: this.getHeaders()
            }
        );
    }

    sellers_out(id) {
        return http.get(`/admin/requests/${id}/sellers/out`, {
            headers: this.getHeaders()
        });
    }

    postpone(id, expiration_date) {
        return http.patch(
            `/admin/requests/${id}/postpone`,
            { expiration_date },
            {
                headers: this.getHeaders()
            }
        );
    }

    show(id) {
        return http.get(`/admin/requests/${id}`, {
            headers: this.getHeaders()
        });
    }

    last_offers(id, params) {
        return http.get(`/providers/requests/${id}/products/offers/last`, {
            headers: this.getHeaders(),
            params
        });
    }

    request_products(id, params) {
		return http.get(`/providers/requests/${id}/products`, {
            headers: this.getHeaders(),
            timeout: 20000,
            params: params
        });
	}
}
