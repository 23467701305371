<template>
    <!-- <div class="col p-0">
        <div class="col p-0">
            <cc-loader v-show="loading"/>
            <no-data-available v-if="!loading && products && products.length == 0" :text="'Nenhum produto encontrado'" />
            <table class="table" v-show="!loading && products && products.length > 0">
                <thead>
                    <tr>
                        <th class="pl-2"></th>
                        <th>Produto</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="prod in products" v-bind:key="prod.id">
                        <td class="check"><i v-show="prod.last_offer_price > 0" class="fas fa-check-double"/></td>
                        <td class="text-uppercase">{{ prod.name }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div> -->
    <div>
        <div class="buttons-container">
            <Button v-if="!isSellersManagement" :iconName="'arrow_back'" title="Voltar" :text="'Voltar'" :action="() => { close() }" />
        </div>
        <cc-loader v-if="loading" />
        <div v-if="!loading" class="page-table-container">
            <div class="page-table-content">
                <div class="page-table-header">
                    <div class="page-table-header-text status-width">Status</div>
                    <div class="page-table-header-text client-width">Cliente</div>
                </div>
                <div v-for="(prod, idx) in products" :key="prod.id" :class="getSpecialBackground(idx)">
                    <div class="page-table-row">
                        <div class="page-table-desc-column status-width">
                            <span style="color: #20CE88;" v-show="prod.last_offer_price > 0" class="material-icons-outlined">
                                done
                            </span>
                        </div>
                        <div class="page-table-desc-column client-width">
                            {{ prod.name }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RequestService from '@/services/v2/requests.service'
import RequestServiceV3 from '@/services/v3/request.service'
import ErrorHandlerService from "@/services/ErrorHandlerService";
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import Button from '@/components/ui/buttons/StandardButton.vue';

export default {
    mixins: [loaderMixin],
    components: {
        Button,
    },
    props: ["request_id", "seller_id", "closeOffersPartial", "isSellersManagement"],
    data() {
        return {
            page: 1,
            items_by_page_value: 10,
            loading: false,
            svc: new RequestService(),
            v3_svc: new RequestServiceV3(),
            products: [],
            filters: {
                attending: true
            },
            total_items: 0
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        getSpecialBackground(idx) { if (idx % 2 != 0) return 'page-table-line-special' },
        load_products(page = this.page) {
            this.loading = true;
            this.page = page
            let params = { seller_id: this.seller_id, load_derivatives: 0 }
            return this.v3_svc.request_products(this.request_id, params).then(response => response.data)
                .then(data => {
                    this.products = data
                    this.v3_svc.last_offers(this.request_id, { seller_id: this.seller_id })
                        .then(response => response.data)
                        .then(data => {
                            this.loading = false;
                            this.products.filter((p) => p && !p.is_generic)
                                .forEach((p) => p.derivatives = [{ ...p, main_product: p }])
                            this.products.forEach((p) => {
                                this.process_last_offer(p, data)
                            })
                            this.$forceUpdate()
                        })
                }).catch(err => {
                    ErrorHandlerService.handle(err, this.$store);
                })
        },
        process_last_offer(prod, offers) {
            offers.forEach((offer) => {
                if (prod.id == offer.main_product_id) {
                    prod.last_offer_price = prod.previous_offer || offer.last_offer
                }
                prod.derivatives.forEach((deriv) => {
                    if (deriv.id == offer.product_id && offer.last_offer > 0) {
                        prod.last_offer_price = deriv.previous_offer || offer.last_offer
                        deriv.last_offer_price = deriv.previous_offer || offer.last_offer
                    }
                })
            })
        }
    },
    mounted() {
        this.load_products()
    }
}
</script>

<style lang="scss">
@import '@/sass/commons/_variables';

.check {
    text-align: center;

    i {
        color: #4267b2;
    }
}

.page-table-container {
    margin: 2em;
    max-height: 50vh;
    overflow: auto;
}

.page-table-header {
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    font-weight: 400;
    color: #605F5F;
    display: flex;
    padding: 1em 0em;
}

.page-table-header-text {
    padding-left: 10px;
    font-weight: 600;
    font-size: 1.19em;
    color: #505050;
}

.page-table-row {
    display: flex;
    font-weight: 300;
    font-size: 1.2em;
    color: #605F5F;
    align-items: center;
}

.page-table-desc-column {
    padding: 0.5em 0.7em;
    text-align: left;
}

.page-table-line-special {
    background: #F7F7F7;
}

.buttons-container {
    padding: 1em 2em;
    display: flex;
    flex-direction: row-reverse;
}

.status-width {
    width: 10%;
}

.client-width {
    width: 90%;
}
</style>
