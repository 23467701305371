<template>
    <div class="col p-0">
        <div style="padding: 1em" class="col-12 pb-4 d-flex align-items-center">
            <div class="col col-4">
                <cc-search v-model="filters.name" :placeholder="'Filtrar por nome'"/>
            </div>
            <div class="col">
                <toggle-button
                    v-model="filters.attending"
                    :color="'#ff7110'"
                    :height="30"/>
                Em atendimento
            </div>
            <StandardButton :action="()=>$emit('close')" text="Voltar" />
        </div>
        <div class="col p-0">
        <cc-loader v-show="loading"/>
        <no-data-available v-if="!loading && clients && clients.length == 0" :text="'Nenhum cliente em atendimento'" />
        <div v-show="!loading && clients && clients.length > 0" >
          <div class="page-table-content">
              <div class="page-table-header">
                  <div class="page-table-header-text name-width">Nome</div>
                  <div class="page-table-header-text group-width">Grupo</div>
                  <div class="page-table-header-text phone-width">Telefone</div>
                  <div class="page-table-header-text participation-width">Participação</div>
                  <div class="page-table-header-text active-width">Ativo</div>
              </div>
              <div v-for="(client, idx) in clients" 
                   :key="client.id"
                   :class="getSpecialBackground(idx)">
                  <div class="page-table-row">
                      <div @click="setSearch(client.group_name)" class="page-table-desc-column name-width name-special">{{ client.name }}</div>
                      <div class="page-table-desc-column group-width">{{ client.group_name }}</div>
                      <div class="page-table-desc-column phone-width">
                        <cc-whatsapp :phone="client.phone" />
                      </div>
                      <div class="page-table-desc-column participation-width">
                        <cc-percentage v-if="client.sellers && client.sellers.length > 0"
                                       :value="client.sellers[0].pivot.participation" />
                      </div>
                      <div class="page-table-desc-column active-width">
                        <toggle-button :value="client.is_attending"
                                       @change="toggle_client(client)"
                                       :sync="true"
                                       :color="'#ff7110'"
                                       :height="20"/>
                      </div>
                  </div>
              </div>
          </div>
          <div v-show="total_items > 15">
            <Pagination classes="orange justify-end"
                        :total="total_items"
                        :items_by_page_value="items_by_page_value"
                        :page="page"
                        :page_limit="9"
                        v-on:change="($event) => {
                            load_available_clients($event)
                            return $event
                        }">
            </Pagination>
          </div>
      </div>
        
            <!-- <table class="table" v-show="!loading && clients && clients.length > 0">
                <thead>
                    <tr>
                        <th class="pl-2">Nome</th>
                        <th>Grupo</th>
                        <th>Telefone</th>
                        <th>Participação</th>
                        <th>Ativo</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="client in clients" v-bind:key="client.id">
                        <td>{{ client.name }}</td>
                        <td class="text-uppercase">{{ client.group_name }}</td>
                        <td><cc-whatsapp :phone="client.phone" /></td>
                        <td>
                            <cc-percentage v-if="client.sellers && client.sellers.length > 0"
                                :value="client.sellers[0].pivot.participation" />
                        </td>
                        <td>
                            <toggle-button
                                :value="client.is_attending"
                                @change="toggle_client(client)"
                                :sync="true"
                                :color="'#ff7110'"
                                :height="20"/>
                        </td>
                    </tr>
                    <tr v-show="total_items > 15">
                        <td colspan="4">
                            <Pagination
                                classes="orange justify-end"
                                :total="total_items"
                                :items_by_page_value="items_by_page_value"
                                :page="page"
                                :page_limit="9"
                                v-on:change="($event) => {
                                    load_available_clients($event)
                                    return $event
                                }"
                            ></Pagination>
                        </td>
                    </tr>
                </tbody>
            </table> -->
        </div>
    </div>
</template>

<script>
import SellerService from '@/services/v3/providers/seller.service'
import ErrorHandlerService from "@/services/ErrorHandlerService";
import Pagination from "@/components/cliente/base-components/Pagination";
import StandardButton from '../../../components/ui/buttons/StandardButton.vue';

export default {
    props: [ "sellerId", "setSearch"],
    components: {
        Pagination,
        StandardButton
    },
    data() {
        return {
            page: 1,
            items_by_page_value: 10,
            loading: false,
            svc: new SellerService(),
            clients: [],
            filters: {
                attending: true
            },
            total_items: 0
        }
    },
    watch: {
        filters: {
            handler: function() {
                this.page = 1
                this.load_available_clients()
            },
            deep: true
        }
    },
    methods: {
        getSpecialBackground (idx) { if (idx % 2 != 0) return 'page-table-line-special' },
        toggle_client(client) {
            this.svc.toggle_client(client, this.sellerId).then(resp => {
                this.load_available_clients()
            })
        },
        load_available_clients(page = this.page) {
            this.loading = true
            this.page = page
            let params = { ...this.filters, page: this.page, per_page: this.items_by_page_value }
            return this.svc.available_clients(this.sellerId, params).then(response => response.data)
            .then(data => {
                this.total_items = data.meta.total
                this.clients = data.data
                this.loading = false
            }).catch(err => {
                ErrorHandlerService.handle(err, this.$store);
            })
        }
    },
    mounted() {
        this.load_available_clients()
    }
}
</script>

<style lang="scss">
    @import '@/sass/commons/_variables';

.page-table-header {
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    font-weight: 400;
    color: #605F5F;
    display: flex;
    padding: 1em 0em;
}

.page-table-header-text {
    padding-left: 10px;
    font-weight: 600;
    font-size: 1.19em;
    color: #505050;
}

.page-table-row {
    display: flex;
    font-weight: 300;
    font-size: 1.2em;
    color: #605F5F;
    align-items: center;
}

.page-table-desc-column {
    padding: 0.5em 0.7em;
    text-align: left;
}
.page-table-line-special {
  background: #F7F7F7;
}
.name-width {
    width: 40%;
}
.group-width {
    width: 20%;
}
.phone-width {
    width: 20%;
}
.participation-width {
    width: 10%;
}
.active-width {
    width: 10%;
}
.name-special{
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
}
</style>
